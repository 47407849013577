import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import LoadContainer from '../../components/LoadContainer';
import { selectOverlay } from '../../components/overlay';
import './stylesVision.scss';


function ViewVision ( { selectOverlay } ) {
    return (
        <LoadContainer>
            <img src={ process.env.PUBLIC_URL + '/img/vision/vision-bg.jpg' } width='1920' height='1080' alt='' />
            <button className='vision--button__left-third' 
                onClick={ () => selectOverlay( { type:'video', src: 'https://vimeo.com/995653838?share=copy', options: { fullScreen: true } } ) }></button>
            <NavLink className='vision--button__center-third' to='/fly-through'></NavLink>

            
            <NavLink className='vision--button__right-third' to='/precinct-overview'></NavLink>
        </LoadContainer>
    );
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
    selectOverlay: (data) => dispatch(selectOverlay(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewVision);