

/*
	Message for Malcolm
	In order to change the layout of this grid
	change 'width', 'height' and 'gutter' below
*/



const width = 460;
const height = 303;
const gutter = 21;

export const dataCGIGallery1 = [
  { title: '1', left: '60px', top: gutter + 'px', src: '/img/gallery/gallery_image_1_thumb.jpg', href: '/img/gallery/gallery_image_1_enlarge.jpg' },
	{ title: '2', left: width + 55 + 'px', top: gutter + 'px', src: '/img/gallery/gallery_image_2_thumb.jpg', href: '/img/gallery/gallery_image_2_enlarge.jpg' },
	{ title: '3', left: width  * 2 + gutter + 30 + 'px', top: gutter + 'px', src: '/img/gallery/gallery_image_3_thumb.jpg', href: '/img/gallery/gallery_image_3_enlarge.jpg' },
	{ title: '4', left: width * 3 + 45 + 'px', top: gutter + 'px', src: '/img/gallery/gallery_image_4_thumb.jpg', href: '/img/gallery/gallery_image_4_enlarge.jpg' },
  { title: '5', left: '60px', top: (height + gutter * 2) + 'px', src: '/img/gallery/gallery_image_5_thumb.jpg', href: '/img/gallery/gallery_image_5_enlarge.jpg' },
	{ title: '6', left: width + 55 + 'px', top: (height + gutter * 2) + 'px', src: '/img/gallery/gallery_image_6_thumb.jpg', href: '/img/gallery/gallery_image_6_enlarge.jpg' },
	{ title: '7', left: width  * 2 + gutter + 30 + 'px', top: (height + gutter * 2) + 'px', src: '/img/gallery/gallery_image_7_thumb.jpg', href: '/img/gallery/gallery_image_7_enlarge.jpg' },
	{ title: '8', left: width * 3 + 45 + 'px', top: (height + gutter * 2) + 'px', src: '/img/gallery/gallery_image_8_thumb.jpg', href: '/img/gallery/gallery_image_8_enlarge.jpg' },
  { title: '9', left: '60px', top: (height * 2) + gutter * 3 + 'px', src: '/img/gallery/gallery_image_9_thumb.jpg', href: '/img/gallery/gallery_image_9_enlarge.jpg' },
	{ title: '10', left:  width + 55 + 'px', top:  (height * 2) + gutter * 3 + 'px', src: '/img/gallery/gallery_image_10_thumb.jpg', href: '/img/gallery/gallery_image_10_enlarge.jpg' },
	{ title: '11', left: width  * 2 + gutter + 30 + 'px', top:  (height * 2) + gutter * 3 + 'px', src: '/img/gallery/gallery_image_11_thumb.jpg', href: '/img/gallery/gallery_image_11_enlarge.jpg' },
	{ title: '12', left: width * 3 + 45 + 'px', top:  (height * 2) + gutter * 3 + 'px', src: '/img/gallery/gallery_image_12_thumb.jpg', href: '/img/gallery/gallery_image_12_enlarge.jpg' },
	];
