
import React from 'react';
import { NavLink } from 'react-router-dom';


export default function ConnectSecondaryMenu ( { routes } ) {
    
    const menuItems = [];
    let currMenuItem;
    for (let idx = 0; idx < routes.length; idx++) { 
        currMenuItem = routes[idx];
        menuItems.push(
            <li key={ idx } className='secondary-menu--link-container' style={{ }}>
                <NavLink 
                    className={ ({ isActive }) => isActive ? ' secondary-menu--link__selected' : 'secondary-menu--link' }
                    to={ currMenuItem.path }>
                        { currMenuItem.title }
                </NavLink>
            </li>
        );
    }

    return (
        <div className='secondary-menu'>
            <ul className=''>
                { menuItems }
            </ul>
        </div>
    );
}