import React from 'react';
import ViewSlider from '../../components/slider/ViewSlider';
import ConnectViewGallery from '../../components/gallery/ConnectViewGallery';
import { dataCGIGallery1 } from '../../data/gallery';


export default function ViewGallery () {
    const slideList = [
        {
            type: 'raw',
            slide: <ConnectViewGallery data={ dataCGIGallery1 }/>,
        },
    ];

    const settings = {
        autoplay: false,
		speed: 500,
		infinite: false,
        dotsClass: 'slick-dots slick-dots__low',
	};

    return (
        <ViewSlider className="slider--transform-container" data={ { slideList: slideList, sliderOptions: settings } } />
    );

}