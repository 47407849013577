import { combineReducers } from 'redux';
// import precinctsReducer from '../routes/precincts/index';
// import featuresReducer from '../routes/features';
// import majorProjectsReducer from '../routes/majorProjects/';
// import aerialMapReducer from '../routes/aerialMap/';
// import amenitiesReducer from '../routes/amenities/';
// import residencesReducer from '../routes/building/';
import overlayReducer from '../components/overlay/';
import multipageReducer from '../components/multipage/';

export default combineReducers( {
  // precincts: precinctsReducer,
  // features: featuresReducer,
  // majorProjects: majorProjectsReducer,
  // aerialMap: aerialMapReducer,
  // amenities: amenitiesReducer,
  // residences: residencesReducer,
  overlay: overlayReducer,
  multipage: multipageReducer,
} );