import React, { useState } from 'react';
import { connect } from 'react-redux';
import CarouselGroup from './CarouselGroup';


function ViewItem ( { item, filePrefix, fileSufix, currItem, carouselItems, buttonCarousel } ) {
  
  //replace spaces with - and remove grammar
  const itemStripped = item.replace(/\s/g, '-').replace(/’/g, '').replace(/&/g, 'and').replace(/\//ig, '-').toLowerCase();
  const iconSrc = filePrefix + itemStripped + fileSufix;
  //only if it's the currently active item
  let display = item === currItem ? 'block' : 'none';

  
  

  if (currItem.title === 'Home') {
    display = 'block';
  }

  if (item === currItem) {
    display = 'block';
  }

  const [index, setIndex]= useState(0);

  const updateGroup = (direction) => {
    if (direction === 'up') {
      if (index < carouselItems.length - 1) {
        const newNumber = index + 1;
        setIndex(newNumber);
      }
    } else {
      if (direction === 'down') {
        if (index > 0) {
          const newNumber = index - 1;
          setIndex(newNumber);
        }
      } 
    }
  }

  if (buttonCarousel && carouselItems) {
    return (
      <>
        {/* <div className='option-1' style={{ display: display }} onClick={() =>  setIndex(0)} /> */}
        <div className='option-2' style={{ display: display }} onClick={() =>  setIndex(0)} />
        <div className='option-3' style={{ display: display }} onClick={() =>  setIndex(1)} />
        <CarouselGroup carouselItems={carouselItems} currItem={currItem} display={display} index={index} />
      </>
    )  
  } else if (carouselItems) {
    return (
      <>
        <div className='left-arrow' style={{ display: display, cursor:  index === carouselItems.length - 1 ? 'pointer' : 'default' }} onClick={() => updateGroup('down')} />
        <div className='right-arrow' style={{ display: display, cursor: index === 0 ? 'pointer' : 'default'}} onClick={() => updateGroup('up')} />
        <CarouselGroup carouselItems={carouselItems} currItem={currItem} display={display} index={index} />
      </>
    )  
  }

  return (
      <img key={currItem.title} className='shared--img-fill' style={ { display: display } } src={ process.env.PUBLIC_URL + iconSrc } width='1920' height='1080' alt='item' />
  );
  
}


const mapStateToProps = (state) => ({
  currItem: state.multipage.currItem
})

export default connect(mapStateToProps)(ViewItem);