
import React from 'react';
import ConnectButtonItem from './ConnectButtonItem';
import './stylesMultipage.scss'


export default function ViewMultipageMenu ( { pages } ) {
    
    const menuItems = [];
    let currMenuItem;
    for (let idx = 0; idx < pages.length; idx++) { 
        currMenuItem = pages[idx];
        if (currMenuItem.inMenu) {
            menuItems.push(
                <li key={ idx } className='multipage--btn-container'>
                    <ConnectButtonItem item={ currMenuItem.title } isSelected={ currMenuItem.default }/>
                </li>
            );
        }
        
    }

    return (
        <div className='multipage--menu'>
            <ul className='multipage--menu-options'>
                { menuItems }
            </ul>
        </div>
    );
}