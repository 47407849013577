import React from 'react';
import ViewSlider from '../../components/slider/ViewSlider';
import { NavLink } from 'react-router-dom';
import './home.scss';


export default function ViewHome ({ slides, showCloseButton }) {
    const slideList = slides.map((img) => ({ src: img, options: {} }) );

    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true,
		speed: 500,
		infinite: true,
	};

    return (
        <div>
            { showCloseButton && <NavLink className='overlay--close-btn-image' to='/vision'>Close &nbsp; </NavLink> }
            <ViewSlider data={ { slideList: slideList, sliderOptions: settings } } />

        </div>
    );

}