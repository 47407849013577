const data = {
    filePrefix: '/img/location/',
    fileSufix: '-bg.jpg',
    backgroundImage: '/img/location/train.jpg',
    pages: [
        { title: 'Overview', default: true, inMenu: true },
        { title: 'Lifestyle', inMenu: true },
        { title: 'Train', inMenu: true },
        { title: 'Tram', inMenu: true },
        { title: 'Dining', inMenu: true },
        { title: 'Shopping', inMenu: true },
        { title: 'Arts & Culture', inMenu: true },
        { title: 'Wellness', inMenu: true },
    ]
};

export default data;