
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { dataRoutes } from './dataRoutes';

// TODO - consider transition between routes
// import { 
//     TransitionGroup,
//     CSSTransition
//   } from 'react-transition-group';


export default function ViewRoutes () {
    
    let location = useLocation();
    let currKeyIndex = 0;

    const getRoutes = (routeList) => {
        let routes = [];

        for (let idx = 0; idx < routeList.length; idx++) { 
            const currRoute = routeList[idx];
            // if it has a path and element then push to routes
            if ( currRoute.path && currRoute.element ) {
                routes.push(
                    getRouteComponent(currRoute)
                );
            }
            // if it has and array of children get and add them to routes
            if ( Array.isArray(currRoute.routes) ) {
                routes = routes.concat( getRoutes(currRoute.routes) );
            }
        }

        return routes;
    }

    const getRouteComponent = (route) => {
        currKeyIndex++;
        return (
            <Route 
                key={ currKeyIndex } 
                path={ route.path } 
                element={ <>{ getHelmet(route.title) } { route.element }</> } />
        );
    }

    function getHelmet(title) {
        return (
            <Helmet>
                <title>{ title + ' - 111 Bourke - East End Place' }</title>
                <meta name='description' content={ title + ' - 111 Bourke - East End Place' } />
            </Helmet>
        );
    }


    return (
        <Routes location={ location }>
            { getRoutes(dataRoutes) }
        </Routes>
    );

}