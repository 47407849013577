
import ViewHome from './home/ViewHome';
import ViewVision from './vision/ViewVision';
import ViewGallery from './gallery/ViewGallery';

import ViewMultipage from '../components/multipage/ViewMultipage';
import dataLocation from '../data/locations';
import dataAmenity from '../data/amenities.js';
import dataFlyThrough from '../data/flythrough.js';
import ViewDataPage from '../components/datapage/ViewDataPage.js';
import ViewImage from '../components/viewImage/ViewImage.js';
import ViewTeam from './team/ViewTeam.js';
import ViewBuilding from './building/ViewBuilding.js';
export const dataRoutes = [
    {
        title: 'Vision',
        path: '/vision',
        element: <ViewVision/>
    },
    {
        title: 'Precinct Overview',
        path: '/precinct-overview',
        inMenu: false,
        element: <ViewImage slides={["/img/vision/precinctoverview-bg.jpg", "/img/vision/precinctoverview-bg-2.jpg"]} showCloseButton={true} />
    },
    {
        title: 'Fly Through',
        path: '/fly-through',
        inMenu: false,
        element: <ViewDataPage type="iframe" data={dataFlyThrough} />
    },
    {
        title: 'Location',
        path: '/location',
        element: <ViewMultipage data={ dataLocation } />
    },
    {
        title: 'Amenities',
        path: '/amenities',
        element: <ViewMultipage data={dataAmenity} />,
        
    },
    {
        title: 'Building',
        path: '/building',
        element: <ViewBuilding slides={["/img/building/building-bg.jpg"]} />
    },
    
    {
        title: 'Team',
        path: '/team',
        element: <ViewTeam slides={["/img/team/team-bg.jpg", "/img/team/team-bg-2.jpg"]} />
    },
    {
        title: 'Gallery',
        path: '/gallery',
        element: <ViewGallery />
    },
    {
        title: 'Home',
        path: '/',
        element: <ViewHome slides={['/img/home/home-01-bg.jpg',
            '/img/home/home-02-bg.jpg',
            '/img/home/home-03-bg.jpg']} />
    }
];