import React, { useEffect } from 'react';
import './image.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import ViewSlider from '../../components/slider/ViewSlider';

function ViewBuilding ( { slides, showCloseButton } ) {
    
    const slideList = slides.map((img) => ({ src: img, options: {} }) );

    const settings = {

        dots: true,

        fade: true,
	};
    return (
        <>
            { showCloseButton && <NavLink className='overlay--close-btn-image' to='/vision'>Close &nbsp; </NavLink> }
            <ViewSlider data={ { slideList: slideList, sliderOptions: settings } } />

        </>

    );
}


export default ViewBuilding;