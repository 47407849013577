const data = {
  filePrefix: '/img/amenities/',
  fileSufix: '-bg.jpg',

  backgroundImage: '/img/amenities/amenity-bg.jpg',
  pages: [
      { title: 'Amenities', inMenu: false, default: true, carouselItems: ['/img/amenities/amenity-bg.jpg']},
      { title: 'Arrival', inMenu: true, carouselItems: ['/img/amenities/arrival-01-bg.jpg', '/img/amenities/arrival-02-bg.jpg']},
      { title: 'Flex', inMenu: true },
      { title: 'Podium', inMenu: true },
      { title: 'Tower', inMenu: true },
      { title: 'End of Trip', inMenu: true },
      { title: 'Wellness', inMenu: true },
      { title: 'ESG', inMenu: true },
      
      { title: 'Technology', inMenu: true },
      { title: 'Co-curate', buttonCarousel: true, carouselItems: ['/img/amenities/co-curate-01-bg.jpg', '/img/amenities/co-curate-02-bg.jpg','/img/amenities/co-curate-03-bg.jpg'], inMenu: true },
  ]
};

export default data;