
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ViewMultipageMenu from './ViewMultipageMenu';
import ConnectViewItem from './ConnectViewItem';
import LoadContainer from '../LoadContainer';
import './stylesMultipage.scss'
import { selectItem } from '.';


function ViewMultipage ( { data, selectItem } ) {
    
    const items = [];
    let currItem;
    for (let idx = 0; idx < data.pages.length; idx++) { 
        currItem = data.pages[idx];
        const carouselItems = currItem.carouselItems;
        const buttonCarousel = currItem.buttonCarousel || false;

        items.push(
            <ConnectViewItem key={ idx } filePrefix={ data.filePrefix } fileSufix={ data.fileSufix } item={ currItem.title } carouselItems={carouselItems} buttonCarousel={buttonCarousel} />
        );
    }

    return (
        <div className='multipage--transform-container'>
            <LoadContainer>
                <img className='shared--img-fill multipage--bkg' src={data.backgroundImage} width='1480' height='980' alt='Bkg' />
                <div className='multipage--frg'>
                    { items }
                </div>
            </LoadContainer>
            <ViewMultipageMenu pages={ data.pages } />
        </div>
    );
}

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => ({
    selectItem: (item) => dispatch(selectItem(item))

});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMultipage);